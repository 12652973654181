<template>
  <div>
    <div class="spread-cols-to-ends mb-2">
      <h5 class="mt-2">Find a service.</h5>
      <b-button size="sm" @click="$bvModal.show('filters-modal')" class="mb-2" variant="primary">
        <b-icon-search class="mr-1"></b-icon-search>
        Search Filters
      </b-button>
      <b-modal size="sm" id="filters-modal" hide-footer>
        <template #modal-title>
          Search Filters
        </template>
        <b-form-group label="Search by Title" label-for="title_filter">

          <b-form-input id="title_filter" v-model="filter.search" name="title_filter"
            placeholder="Enter words to search for ...">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Category" label-for="categories_filter">

          <b-form-select @change="getGigs(true)" id="categories_filter" v-model="filter.category"
            name="categories_filter">
            <option :value="null">All Categories</option>
            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.description }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Request or Offer" label-for="type_filter">

          <b-form-select @change="getGigs(true)" id="type_filter" v-model="filter.gig_type" name="type_filter">
            <option :value="null">Both Requests and Offers</option>
            <option :value="'offers'">Requests only</option>
            <option :value="'requests'">Offers only</option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Location" label-for="location_filter">

          <b-form-checkbox @change="getGigs(true)" id="location_filter" v-model="filter.distance" name="location_filter"
            :value="true" :unchecked-value="false" :disabled="!location_available">
            Show gigs closest to me.
            <b-form-text v-if="location_available">
              To ensure accuracy of results, you may update your location here - <router-link
                :to="'/account/profile/location-settings'" tag="a">Location Settings</router-link>
            </b-form-text>
            <b-form-text v-else>
              This filter requires we have access to your location. Please update your location here - <router-link
                :to="'/account/profile/location-settings'" tag="a">Location Settings</router-link>
            </b-form-text>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Favorites" label-for="favorites_filter">

          <b-form-checkbox @change="getGigs(true)" id="favorites_filter" v-model="filter.favorited"
            name="favorites_filter" :value="true" :unchecked-value="false">
            Show only my favorite gigs.
          </b-form-checkbox>
        </b-form-group>
        <b-button :disabled="loading" variant="success" class="mt-3" block @click="$bvModal.hide('filters-modal')">
          {{ loading ? 'Loading, please wait ...' : 'Show Results ( ' + gigs_count + ' )' }}
        </b-button>
      </b-modal>
    </div>

    <CustomLoader v-if="loading && gigs.length === 0" :center="true"></CustomLoader>
    <div class="container div-no-show-scroll" id="infinite-list-dynamic-view" style="height: 90vh;">
      <div class="row" ref="masonryContainer">
        <b-col class="gig-tile" cols="6" md="3" v-for="gig in gigs" :key="gig.id">
          <!-- Pass gig to show gig tile -->
          <GigTile @image-loaded="initMasonry" :show_location="location_available" :gig="gig"></GigTile>
        </b-col>
      </div>
      <CustomLoader class="mb-5" v-if="loading && gigs.length > 0" :center="false"></CustomLoader>
      <BottomFooter v-if="gigs.length === pagination.totalRows"></BottomFooter>
    </div>
  </div>
</template>

<script>
import GigService from "@/services/GigService";
import GigTile from "@/components/Workmorr/GigTile";
import { BIconSearch } from 'bootstrap-vue';
import CustomLoader from "@/components/Workmorr/CustomLoader";
import BottomFooter from "@/components/Workmorr/BottomFooter";
import Masonry from 'masonry-layout';

export default {
  name: "GigListings",
  components: { CustomLoader, GigTile, BIconSearch, BottomFooter },
  async mounted() {
    this.getGigs(false);
    this.getGigCategories();
    this.user = this.$store.getters['user/user'];
    if (this.user !== null && this.user.location !== null) {
      this.location_available = true;
    }

    const listElm = document.querySelector('#infinite-list-dynamic-view');
    // eslint-disable-next-line no-unused-vars
    listElm.addEventListener('scroll', e => {
      if (listElm.scrollTop + listElm.clientHeight + 150 >= listElm.scrollHeight && this.loading === false) {
        this.filter.page++;
        this.getGigs(false);
      }
    });

    this.initMasonry();

    // Create MutationObserver to detect DOM changes inside the masonry container
    const observer = new MutationObserver(() => {
      this.initMasonry(); // Reinitialize Masonry
    });

    const config = { childList: true, subtree: true };
    observer.observe(this.$refs.masonryContainer, config);

    // Store the observer to disconnect it in beforeDestroy hook
    this.mutationObserver = observer;
  },

  computed: {
    search_filter() {
      return this.filter.search;
    }
  },

  data() {
    return {
      loading: false,
      user: null,
      gigs: [],
      gigs_count: 0,
      filter: {
        page: 1,
        owned: false,
        recordsPerPage: 32,
        type: 'records',
        search: null,
        category: null,
        gig_type: null,
        distance: false,
        favorited: false
      },
      categories: {},
      location_available: false,
      timeout: null,
      pagination: {
        totalRows: 1000, // Placeholder so get is run at least once
      },
    }
  },

  methods: {
    getGigs(search) {
      if (this.gigs.length < this.pagination.totalRows || search) {
        this.loading = true;

        if (search) {
          this.gigs = [];
          this.filter.page = 1;
        }

        GigService.getGigs(
          this.filter.page,
          this.filter.owned,
          this.filter.recordsPerPage,
          this.filter.type,
          this.filter.search,
          this.filter.category,
          this.filter.distance,
          this.filter.favorited,
          this.filter.gig_type
        ).then((response) => {
          response.data.records.data.forEach(gig => {
            this.gigs.push(gig);
          });
          this.pagination.totalRows = response.data.records.total;
          this.gigs_count = response.data.records.total;
        }).finally(() => {
          this.loading = false;
          this.layoutMasonry(); // Ensure Masonry updates after fetching gigs
        });
      }
    },

    getGigCategories() {
      GigService.getGigCategories().then((response) => {
        this.categories = response.data.categories;
      })
    },

    layoutMasonry() {
      if (this.masonryInstance) {
        this.masonryInstance.layout(); // Recalculate the layout
      }
    },



    initMasonry() {
      const container = this.$refs.masonryContainer;
      if (!container) return;

      this.masonryInstance = new Masonry(container, {
        itemSelector: '.gig-tile',
        percentPosition: true,
        gutter: 0,
      });
    },
  },

  watch: {
    search_filter() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        this.getGigs(true)
      }.bind(this), 1000);
    },

    gigs() {
      this.$nextTick(() => {
        this.initMasonry();
      });
    },
  },

  beforeDestroy() {
    if (this.masonryInstance) {
      this.masonryInstance.destroy();
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect(); // Stop observing DOM changes
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

/* @media (max-width: 1024px) {
  .gig-tile {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
} */

.gig-tile {
  padding: 0px !important;
  break-inside: avoid;
}
</style>
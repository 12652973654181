<template>
  <div class='custom-text-center'>
    <span v-if="message" class="small">{{ message }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CenterMessage",
  inheritAttrs: false,
  props: [
      'message'
  ]
}
</script>

<style scoped>
  .custom-text-center{
    z-index: 9;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
</style>
<template>
  <div class="m-2" v-if="gig_data">
    <b-card no-body img-alt="Gig Image" img-top style="border-radius: 10px" :class="shadowClass" footer-tag="footer">
      <b-card-body>
        <div class="spread-cols-to-ends mt-0 mb-1">
          <div>
            <span v-if="gig_data.images.length > 0"
              style="font-size: 0.7rem; display: block; text-align: right"><b-icon-image style="color: darkorange"
                class="font-weight-bold"></b-icon-image> {{ gig_data.images.length }}</span>
          </div>
          <div>
            <span style="font-size: 0.7rem; display: block; text-align: right"><b-icon-eye style="color: #007bff"
                class="font-weight-bold"></b-icon-eye> {{ gig_data.human_readable_views }}</span>
          </div>
        </div>

        <router-link :to="'gigs/view/' + gig_data.id + '/' + gig_data.slug" tag="span" class="mouse-pointer">
          <div class="mb-2"><span style="font-size: 0.8rem"><b>{{ gig_data.short_title }}</b></span></div>
        </router-link>
        <router-link :to="'gigs/view/' + gig_data.id + '/' + gig_data.slug" tag="span" class="mouse-pointer">
          <div class="mb-2" v-if="images.length > 0">
            <viewer :images="images" class="images clearfix">
              <template slot-scope="scope">
                <img v-for="src in scope.images" :src="src" :key="src" class="image" @load="onImageLoad" />
              </template>
            </viewer>
          </div>
        </router-link>
        <div class="spread-cols-to-ends mt-4" style="font-size: 13px">
          <div>
            <span v-if="gig_data.amount > 0" style="color:green; font-weight: bold"> &#8373;{{ gig_data.amount }}</span>
            <b-badge v-else variant="success" class="mb-0">Custom</b-badge>
          </div>
          <div>
            <span class="font-weight-bold">
              <b-icon-stopwatch style="color: darkorange"></b-icon-stopwatch>
              {{ gig_data.human_readable_duration }}
            </span>

          </div>

        </div>
      </b-card-body>

      <template #footer>
        <div class="spread-cols-to-ends" style="font-size: 13px">
          <div>
            <div><span style="font-size: 15px;">{{ gig_data.user.country_flag }}</span><span v-if="gig_data.is_promoted"
                style="font-size: 15px; margin-left: 2px;">🚀</span></div>
            <span class="category-text"><em>{{ gig_data.category.description }}</em></span>
            <div>
              <span style="color:green; font-weight: bold; font-size: 0.7rem">
                {{ gig_data.user.username }}
              </span>
              <b-icon-patch-check-fill variant="warning"
                v-if="gig_data.user.is_kyc_verified && gig_data.user.active_subscription"></b-icon-patch-check-fill>
              <b-icon-patch-check-fill variant="warning"
                v-else-if="gig_data.user.active_subscription"></b-icon-patch-check-fill>
              <b-icon-patch-check-fill variant="primary"
                v-else-if="gig_data.user.is_kyc_verified"></b-icon-patch-check-fill>
            </div>
          </div>
          <div>
            <span style="font-size: 1.2rem" @click="favoriteGig(gig_data.id)" class="mouse-pointer">
              <b-icon v-if="gig_data.favorite" icon="heart-fill" style="color: red"></b-icon>
              <b-icon v-else icon="heart"></b-icon>
            </span>
          </div>

        </div>
        <div class="spread-cols-to-ends mt-1" style="font-size: 0.8rem; min-height: 1rem">
          <div>
            <div v-if="gig_data.user.rating">
              <span class="font-weight-bold" style="color: darkorange">
                <b-icon-star-fill></b-icon-star-fill> {{ gig_data.user.rating }}
              </span>
              <span>( {{ gig_data.user.rating_count }} review{{ gig_data.user.rating_count > 1 ? 's' : '' }} )</span>
            </div>
          </div>
          <div>
            <span v-if="gig_data.distance && gig_data.location_based" class="font-weight-bold">
              <b-icon-map class="text-primary"></b-icon-map>
              {{ gig_data.human_readable_distance }}
            </span>
          </div>
        </div>

      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BIconStopwatch,
  BIcon,
  BIconHeart,
  BIconMap,
  BIconStarFill,
  BIconPatchCheckFill,
  BIconEye,
  BIconImage
} from 'bootstrap-vue'
import GigService from "@/services/GigService";

export default {
  name: "GigTile",
  props: [
    'gig',
    'show_location'
  ],
  components: {
    BIconStopwatch,
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BIconHeart,
    BIconMap,
    BIconStarFill,
    BIconPatchCheckFill,
    BIconEye,
    BIconImage
  },

  data() {
    return {
      gig_data: {},
      images: []
    }
  },


  created() {
    this.gig_data = this.gig;
    // Format images
    if (this.gig.images.length > 0) {
      this.gig.images.forEach((image) => {
        this.images.push(image.file.firebase_url);
      });
    }
  },

  computed: {
    shadowClass() {
      var shadowClass = 'mb-2 shadow-sm';
      if (this.gig_data.is_promoted) {
        shadowClass += ' shadow-promoted';
      } else if (this.gig_data.user.active_subscription) {
        shadowClass += ' shadow-subscribed';
      }
      return shadowClass;
    }
  },

  methods: {
    favoriteGig(gig_id) {
      GigService.favoriteGig(gig_id).then(
        (response) => {
          this.gig_data = response.data.gig;
        }
      )
    },

    onImageLoad() {
      this.$emit('image-loaded');
    }
  },


}
</script>

<style scoped>
.card-body {
  padding: 0.5rem;
}

.card-footer {
  padding: 0.5rem;
  font-size: 13px;
}

.image {
  height: 30px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  border-radius: 7%;
}
</style>